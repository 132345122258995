import type { ReactElement } from 'react';
import styled, { css } from 'styled-components';
import { SectionTemplate } from './SectionTemplate';
import type { ContentSection } from '../../../../amplienceTypes/schemas/exported/section-schema';
import { Categories } from './Categories';
import { colors, media } from '../../../shared/core/styles';
import { useStaticContext } from '../../../../utilities/context/static/StaticContext';

const S = {
  CardSectionWrapper: styled(SectionTemplate)`
    ${({ theme }) => css`
      && {
        background-color: ${colors.WHITE_SMOKE_GREY};

        .col--carousel {
          display: flex;
          flex-wrap: wrap;
          width: 100vw;

          @media ${media(theme).greaterThan('xl')} {
            .has-open-sideNavigation & {
              width: calc(100vw - var(--side-nav-width));
            }
          }

          &::after {
            content: '';
            display: inline-block;
            height: 26px;
            width: 1px;
            margin-left: -1px;

            @media ${media(theme).greaterThan('sm')} {
              height: 34px;
            }
          }
        }

        .contentBlockList {
          display: flex;
          flex-flow: row nowrap;
          align-items: stretch;
          margin: 0 auto;
          overflow-x: auto;
          padding-bottom: 20px;
          max-width: min-content;
          -webkit-overflow-scrolling: touch;
          scroll-snap-type: x mandatory;

          &::-webkit-scrollbar {
            appearance: none;
            height: 2px;
          }

          &::-webkit-scrollbar-thumb {
            border-radius: 0;
            background-color: rgba(0 0 0 / 90%);
            box-shadow: 0 0 1px rgba(255 255 255 / 50%);
          }

          &::-webkit-scrollbar-track {
            background-color: rgba(0 0 0 / 10%);
            margin: 0 20px;
          }

          @media ${media(theme).greaterThan('sm')} {
            padding-bottom: 36px;

            &::-webkit-scrollbar-track {
              margin: 0 36px;
            }
          }

          &::after {
            content: '';
            flex-shrink: 0;
            height: 1px;
            width: 1px;
            margin-left: -1px;
          }
        }

        .contentBlock-bodyAlign {
          padding: 20px;

          @media ${media(theme).greaterThan('sm')} {
            padding: 36px;
          }
        }

        .contentBlock-body-title {
          font-weight: ${({ theme }) => theme.overrides.font?.weight.bold};
          text-transform: uppercase;
          font-size: ${({ theme }) => theme.font.size[7]};
          line-height: 1;
        }

        .col:last-child .contentBlock-bodyAlign {
          padding-top: 0;
        }

        .col > .cardWrapper {
          margin: 0 auto;
        }
      }
    `}
  `,
};

export const Section = (props: ContentSection): ReactElement => {
  const { layout } = props;
  const { accessibilityLabels } = useStaticContext();

  if (layout === 'HP Categories') {
    return (
      <Categories
        {...props}
        ariaRole={accessibilityLabels?.hpCategories?.role}
        ariaLabel={accessibilityLabels?.hpCategories?.label}
      />
    );
  }

  if (layout === 'HP Card Overview') {
    return (
      <S.CardSectionWrapper
        {...props}
        ariaRole={accessibilityLabels?.hpCardOverview?.role}
        ariaLabel={accessibilityLabels?.hpCardOverview?.label}
      />
    );
  }

  return (
    <SectionTemplate
      {...props}
      ariaRole={accessibilityLabels?.hpSection?.role}
      ariaLabel={accessibilityLabels?.hpSection?.role}
    />
  );
};
