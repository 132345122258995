import type { ForwardedRef, ReactElement, MouseEvent } from 'react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import type { ContentReference } from 'dc-delivery-sdk-js/build/main/lib/content/model/ContentReference';
import type { Product as ProductSchema } from '../../../amplienceTypes/schemas/exported/product-schema';
import { Tile } from '../../../components/shared/recommendations/tile/Tile';
import { useAppContext } from '../../../utilities/context/static/AppContext';
import { useLazyLoading } from '../../../utilities/hooks';
import type { RecentlyViewedProduct } from '../../../utilities/helpers';
import { SkeletonTile } from '../../../components/productList/listing/SkeletonTile';
import { fetchProducts } from '../../../utilities/graphql/queries';
import { QuickView } from '../../shared/quickView/QuickView';
import type { TileType } from '../../shared/recommendations/tile/types';
import Attributes from './Attributes';
import { WishlistAction } from '../../pdp/productDetail/productDetailControl/WishlistAction';
import { useApolloContext } from '~/campaign/core/context/ApolloContext';

const S = {
  Attributes: styled(Attributes)``,
};

interface TileProps {
  content?: (ProductSchema & ContentReference) | undefined;
}

export const Product = (content: TileProps): ReactElement => {
  const { sfc, images, hide, override, quickshop, attributes } = content as ProductSchema;
  const { locale, siteType } = useAppContext();
  const { client: apolloClient } = useApolloContext();

  const [product, setProduct] = useState<RecentlyViewedProduct>();

  const documentObserver = useLazyLoading({
    options: {
      root: null,
      rootMargin: '50% 10% 50% 10%',
      threshold: 0,
    },
    once: true,
  });

  const productTileAnchorRef = documentObserver.ref as ForwardedRef<HTMLDivElement>;

  const preventDefault = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  // GET DATA
  useEffect(() => {
    const fetchData = async () => {
      if (sfc) {
        try {
          const fetchedProducts = await fetchProducts({
            locale,
            siteType,
            codes: [sfc],
            apolloClient,
          });

          if (fetchedProducts.products?.length) {
            setProduct(fetchedProducts.products[0] as RecentlyViewedProduct);
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log('Fetch Product Error :: ', error);
        }
      }
    };

    if (sfc && documentObserver.isIntersecting) {
      fetchData();
    }
  }, [sfc, locale, siteType, documentObserver.isIntersecting, apolloClient]);

  return (
    <S.Attributes {...attributes} ref={productTileAnchorRef}>
      {product ? (
        <Tile
          product={product}
          tileType="amplience"
          signingEnabled={false}
          showVariantSelector={false}
          variants={[]}
          overrideImages={images}
          overrideLabels={override}
          hideElements={hide}
          wishlistAction={
            hide?.wishlist !== true && (
              <WishlistAction
                baseProduct={product.baseProduct}
                code={product.code}
                sizeType={product.sizeInformation?.sizeType}
                onClick={preventDefault}
                wishlistButtonType="QuickView"
              />
            )
          }
          quickView={
            quickshop ? (
              <QuickView
                code={product.code || product.baseProduct}
                dataLayerQuickViewProductTileType={'amplience' as TileType}
                pushClickEvent={() => undefined}
                algoliaAddToCartAfterSearchEventName=""
                algoliaAddToCartEventName=""
              />
            ) : (
              <></>
            )
          }
        />
      ) : (
        <SkeletonTile />
      )}
    </S.Attributes>
  );
};
