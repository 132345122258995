import type { PropsWithChildren, ReactElement } from 'react';
import type {
  ContentSection,
  Content1 as ColumnContent,
} from '../../../../amplienceTypes/schemas/exported/section-schema';
import {
  gsPartialColumnLayout,
  gsPartialLayout,
} from '../../../../utilities/constants/homepageHandlebars';
import Attributes from '../../partials/Attributes';
import { ContentBlock } from '../../partials/ContentBlock';
import { SectionDownloads } from '../SectionDownloads';
import { HtmlCode } from '../../external/HtmlCode';
import { HtmlText } from '../HtmlText';
import { ContentBlockVideo } from '../../partials/ContentBlockVideo';
import { StoryCard } from '../StoryCard';
import type { StoryContentPage } from '../../../../amplienceTypes/schemas/exported/story-schema';
import type { Card } from '../../../../amplienceTypes/schemas/exported/card-schema';
import { Product } from '../../partials/Product';

interface SectionTemplateInterface {
  updateColumnRefs?: (el: HTMLDivElement) => void;
  className?: string;
  ariaRole?: string;
  ariaLabel?: string;
}

export const SectionTemplate = ({
  layout,
  column,
  attributes,
  updateColumnRefs,
  className = '',
  children,
  ariaLabel,
  ariaRole,
}: PropsWithChildren<ContentSection & SectionTemplateInterface>): ReactElement => {
  const getMatchingComponent = (columnContent: ColumnContent[0], index: number) => {
    const components = [
      {
        contentLink: 'https://ahc.g-star.com/content/section-downloads.json',
        Component: <SectionDownloads key={index} {...columnContent} />,
      },
      {
        contentLink: 'https://ahc.g-star.com/content/card.json',
        Component: <StoryCard key={index} card={columnContent as unknown as Card} />,
      },
      {
        contentLink: 'https://ahc.g-star.com/page/story.json',
        Component: <StoryCard key={index} story={columnContent as unknown as StoryContentPage} />,
      },
      {
        contentLink: 'https://ahc.g-star.com/content/html-code.json',
        Component: <HtmlCode key={index} {...columnContent} />,
      },
      {
        contentLink: 'https://ahc.g-star.com/content/html-text.json',
        Component: <HtmlText key={index} {...columnContent} />,
      },
      {
        contentLink: 'https://ahc.g-star.com/content/section-video.json',
        Component: <ContentBlockVideo key={index} {...columnContent} />,
      },
      {
        contentLink: 'https://ahc.g-star.com/content/product.json',
        Component: <Product key={index} {...columnContent} />,
      },
    ];

    return (
      // eslint-disable-next-line no-underscore-dangle
      components.find(component => component.contentLink === columnContent._meta.schema)
        ?.Component || <ContentBlock key={index} {...columnContent} />
    );
  };

  return (
    <Attributes
      {...attributes}
      additionalClassNames={`row ${layout === 'HP Categories' ? '' : 'js-row'} ${
        layout ? gsPartialLayout[layout] : ''
      }`}
      className={className}
      ariaRole={ariaRole}
      ariaLabel={ariaLabel}
    >
      {children}
      {column?.map(
        (col, i) =>
          col && (
            <Attributes
              key={i}
              {...col.attributes}
              additionalClassNames={`col ${col.layout ? gsPartialColumnLayout[col.layout] : ''}`}
              ref={updateColumnRefs}
            >
              {col.content &&
                (col.content.length > 1 ? (
                  <div
                    className={`contentBlockList js-contentBlockList${
                      col.layout === 'Carousel' ? ' contentBlockList--slides' : ''
                    }`}
                  >
                    {/* Here goes each section */}
                    {col.content?.map((colContent, j) => getMatchingComponent(colContent, j))}
                  </div>
                ) : (
                  getMatchingComponent(col.content[0], 0)
                ))}
            </Attributes>
          )
      )}
    </Attributes>
  );
};
